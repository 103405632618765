import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IniciarSesionComponent } from './iniciar-sesion/iniciar-sesion.component';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { MaterialModule } from './material.module';
import { MapComponent } from './map/map.component';
import { ShowMapComponent } from './show-map/show-map.component';
import { AgmCoreModule } from '@agm/core';
import { VideoconferenceModule } from './videoconference/videoconference.module';
import { VideoconferenceComponent } from './videoconference/videoconference.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    IniciarSesionComponent,
    MapComponent,
    ShowMapComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCYjfMC9pTaT_GQhFctJO0FM_7v4FM-XQw',
    }),
    VideoconferenceModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }],
  entryComponents: [VideoconferenceComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
