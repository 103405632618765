import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EmpresaTransporte} from '../models/empresa-transporte';

@Injectable({
    providedIn: 'root'
})
export class RutasService {

    public pathAPI = environment.baseUrlApi + '/api/rutas';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    obtenerPuntos(rutaCodigo, eventoCodigo): Observable<any[]> {

        const httpParams = new HttpParams()
            .set('ruta', rutaCodigo)
            .set('evento', eventoCodigo);

        return this.httpClient.get<any[]>(this.pathAPI + '/novedades/ruta/obtener-puntos', {params: httpParams});
    }

    finalizarRuta(rutaCodigo): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.httpClient.post(this.pathAPI + '/' + rutaCodigo + '/finalizar-ruta', null, {headers});
    }
}
