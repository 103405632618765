import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject, of } from 'rxjs';
import { Usuario } from '../models/usuario';
import { Acudiente } from '../models/acudiente';
import { AuthService } from '../auth/auth.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  public pathAPI = environment.baseUrlApi + '/api/usuarios';

  constructor(private httpCliente: HttpClient, private authService: AuthService) {}

  /**
   * Obtener un usuario del sistema
   * @param usuarioCodigo
   */
  obtenerUsuario(usuarioCodigo: number): Observable<Usuario> {
    return this.httpCliente.get<Usuario>(this.pathAPI + '/' + usuarioCodigo);
  }

  /**
   * Obtener todos los usuarios del sistema
   */
  obtenerUsuarios(): Observable<Usuario[]> {
    return this.httpCliente.get<Usuario[]>(this.pathAPI);
  }

  obtenerResumenMensajesActividadesHome() {
    return this.httpCliente.get(this.pathAPI + '/home/resumen-actividad-mensaje');
  }

  obtenerConfiguracionUsuario(): Observable<any> {
    if (this.authService.obtenerConfiguracion()) {
      return of(this.authService.obtenerConfiguracion());
    }

    return this.httpCliente
      .get(this.pathAPI + '/admin/configuracion')
      .pipe(tap((response) => this.authService.guardarConfiguracion(response)));
  }

  /* Inicio API's Admin Rutas */
  obtenerUsuariosAdminRuta(): Observable<Usuario> {
    return this.httpCliente.get<Usuario>(this.pathAPI + '/admin/usuarios-ruta');
  }

  obtenerPadrePorGrupo(grupoCodigo) {
    return this.httpCliente.get<any[]>(this.pathAPI + '/padres/grupo/' + grupoCodigo);
  }

  obtenerProfesoresPorAlumno(codigo) {
    return this.httpCliente.get<any[]>(this.pathAPI + '/estudiantes/' + codigo + '/profesores');
  }

  obtenerUsuarioAdminRuta(usuarioCodigo: number): Observable<Usuario> {
    return this.httpCliente.get<Usuario>(this.pathAPI + '/admin/usuarios-ruta/' + usuarioCodigo);
  }

  actualizarAdminRuta(param: any): Observable<Usuario> {
    return this.httpCliente.put<Usuario>(
      this.pathAPI + '/admin/usuarios-ruta/' + param.codigo,
      param,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  updatePassword(param) {
    const body = new HttpParams().set('email', param.email).set('clave', param.password);

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpCliente.put(this.pathAPI + '/' + param.user, body, {
      headers,
    });
  }

  crearAdminRuta(param: any): Observable<Usuario> {
    return this.httpCliente.post<Usuario>(this.pathAPI + '/admin/usuarios-ruta', param, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  asignarCoordinador(param: any): Observable<Usuario> {
    return this.httpCliente.post<Usuario>(
      this.pathAPI + '/admin/usuario-profesor/coordinador',
      param,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  /* Fin API's Admin Rutas */

  /* Inicio API's Monitor Rutas */
  obtenerUsuariosMonitorRuta(): Observable<Usuario> {
    return this.httpCliente.get<Usuario>(this.pathAPI + '/admin/usuarios-monitor');
  }

  obtenerUsuarioMonitorRuta(usuarioCodigo: number): Observable<Usuario> {
    return this.httpCliente.get<Usuario>(this.pathAPI + '/admin/usuarios-monitor/' + usuarioCodigo);
  }

  actualizarMonitorRuta(param: any): Observable<Usuario> {
    return this.httpCliente.put<Usuario>(
      this.pathAPI + '/admin/usuarios-monitor/' + param.codigo,
      param,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  crearMonitorRuta(param: any): Observable<Usuario> {
    return this.httpCliente.post<Usuario>(this.pathAPI + '/admin/usuarios-monitor', param, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /* Fin API's Monitor Rutas */

  /* Inicio API's Profesores */

  obtenerUsuariosProfesor(school): Observable<Usuario[]> {
    const httpParams = new HttpParams().set('colegioCodigo', school);

    return this.httpCliente.get<Usuario[]>(this.pathAPI + '/admin/usuario-profesor', {
      params: httpParams,
    });
  }

  obtenerUsuarioProfesor(usuarioCodigo: number): Observable<Usuario> {
    return this.httpCliente.get<Usuario>(this.pathAPI + '/admin/usuario-profesor/' + usuarioCodigo);
  }

  actualizarProfesor(param: any): Observable<Usuario> {
    return this.httpCliente.put<Usuario>(
      this.pathAPI + '/admin/usuario-profesor/' + param.codigo,
      param,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  crearProfesor(param: any): Observable<Usuario> {
    return this.httpCliente.post<Usuario>(this.pathAPI + '/admin/usuario-profesor', param, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  obtenerAreasProfesores(): Observable<any> {
    return this.httpCliente.get<any>(this.pathAPI + '/admin/profesor/areas', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /* Fin API's Profesores */
}
