import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AuthService } from './auth/auth.service';
import { Sesion } from './models/sesion';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  hasFullView = false;
  title = 'go2school-web-admin';
  @HostBinding('class') componentCssClass;

  constructor(private activatedRoute: ActivatedRoute, public overlayContainer: OverlayContainer) {}

  onSetTheme(theme) {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }

  private setHasFullView() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.hasFullView = params.hasFullView || false;
    });
  }

  ngOnInit() {
    this.setHasFullView();
  }
}
