import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Group } from '../models/group';
import { Observable } from 'rxjs';
import { GrupoEmpresaTransporteColegio } from '../models/grupo-empresa-transporte-colegio';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private httpCliente: HttpClient) {}

  getGroupByUser() {
    const url = environment.baseUrlApi + '/api/grupos/recursos-usuario';
    return this.httpCliente.get<Group>(url);
  }

  getGroupBySchool(param: any) {
    const url = environment.baseUrlApi + '/api/colegios/' + param.school + '/grupos';
    return this.httpCliente.get<Group[]>(url);
  }

  getClassRoom(param: any) {
    const httpParams = new HttpParams().set('tipo', '2');

    const url = environment.baseUrlApi + '/api/colegios/' + param.school + '/grupos';
    return this.httpCliente.get<Group[]>(url, { params: httpParams });
  }

  getGroupByCompany(param: any) {
    const url =
      environment.baseUrlApi +
      '/api/empresas-transporte/grupos-ruta' +
      '?colegioCodigo=' +
      param.school +
      '&empresaCodigo=' +
      param.company;
    return this.httpCliente.get<Group[]>(url);
  }

  /** GRUPOS DE JARDIN */

  getGroups(page: number = 1, porPagina: number = 25): Observable<any> {
    let httpParams = new HttpParams()
      .set('page', page.toString())
      .set('porPagina', porPagina.toString());

    const url = environment.baseUrlApi + '/api/grupos';

    return this.httpCliente.get<any>(url, { params: httpParams });
  }

  getGroup(grupoCodigo: number): Observable<Group> {
    const url = environment.baseUrlApi + '/api/grupos/' + grupoCodigo;

    return this.httpCliente.get<Group>(url);
  }

  createGroup(grupo: Group): Observable<Group> {
    const url = environment.baseUrlApi + '/api/grupos';

    return this.httpCliente.post<Group>(url, grupo, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  updateGroup(grupo: Group): Observable<Group> {
    const url = environment.baseUrlApi + '/api/grupos';

    return this.httpCliente.put<Group>(url + '/' + grupo.codigo, grupo, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  /** GRUPOS DE JARDIN */

  /** GRUPOS DE RUTA */

  getRouteGroups(page: number = 1, porPagina: number = 25): Observable<any> {
    let httpParams = new HttpParams()
      .set('page', page.toString())
      .set('porPagina', porPagina.toString());

    const url = environment.baseUrlApi + '/api/grupos-rutas';

    return this.httpCliente.get<any>(url, { params: httpParams });
  }

  getRouteGroup(grupoRutaCodigo: number): Observable<GrupoEmpresaTransporteColegio> {
    const url = environment.baseUrlApi + '/api/grupos-rutas/' + grupoRutaCodigo;

    return this.httpCliente.get<GrupoEmpresaTransporteColegio>(url);
  }

  createRouteGroup(
    grupoEmpresaTransporte: GrupoEmpresaTransporteColegio
  ): Observable<GrupoEmpresaTransporteColegio> {
    const url = environment.baseUrlApi + '/api/grupos-rutas';

    return this.httpCliente.post<GrupoEmpresaTransporteColegio>(url, grupoEmpresaTransporte, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  updateRouteGroup(
    grupoEmpresaTransporte: GrupoEmpresaTransporteColegio
  ): Observable<GrupoEmpresaTransporteColegio> {
    const url = environment.baseUrlApi + '/api/grupos-rutas';

    return this.httpCliente.put<GrupoEmpresaTransporteColegio>(
      url + '/' + grupoEmpresaTransporte.codigo,
      grupoEmpresaTransporte,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  createVideoconference(
    groupCode: number,
    name: string,
    welcome: string
  ): Observable<Videoconferencia> {
    const url = environment.baseUrlApi + `/api/grupos/${groupCode}/videoconferencia`;

    return this.httpCliente.post<Videoconferencia>(
      url,
      {
        nombre: name,
        mensaje_bienvenida: welcome,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  getVideoconference(groupCode: number): Observable<Videoconferencia> {
    const url = environment.baseUrlApi + `/api/grupos/${groupCode}/videoconferencia`;

    return this.httpCliente.get<Videoconferencia>(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  joinAsTeacher(groupCode: number) {
    const url = environment.baseUrlApi + `/api/grupos/${groupCode}/videoconferencia/profesor`;

    return this.httpCliente.get<{ url: string }>(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  joinAsStudent(groupCode: number, studentName: string) {
    const url = environment.baseUrlApi + `/api/grupos/${groupCode}/videoconferencia/estudiante`;

    return this.httpCliente.get<{ url: string }>(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        estudiante: studentName,
      },
    });
  }

  /** GRUPOS DE RUTA */
}
