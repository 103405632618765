import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Colegio} from '../models/colegio';

@Injectable({
    providedIn: 'root'
})
export class ColegioService {

    private pathAPI = environment.baseUrlApi + '/api/colegios';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    obtenerColegios(): Observable<any> {
        return this.httpClient.get(this.pathAPI);
    }

    obtenerColegio(colegioCodigo: number): Observable<any> {
        return this.httpClient.get(this.pathAPI + '/' + colegioCodigo);
    }

    obtenerEstudiantesPorGrupoDeRuta(grupo): Observable<any> {
        return this.httpClient.get(this.pathAPI + '/estudiantes/grupos-ruta/' + grupo);
    }

    obtenerEstudiantesColegio(colegio): Observable<any> {
        return this.httpClient.get(this.pathAPI + '/estudiantes/colegio?colegioCodigo=' + colegio);
    }

    obtenerProfesoresColegio(colegio): Observable<any> {
        return this.httpClient.get(this.pathAPI + '/profesores/' + colegio);
    }

    crearColegio(colegio: Colegio): Observable<Colegio> {
        return this.httpClient.post<Colegio>(
            this.pathAPI,
            colegio,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    actualizarColegio(colegio: Colegio): Observable<Colegio> {
        return this.httpClient.put<Colegio>(
            this.pathAPI + '/' + colegio.codigo,
            colegio,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    obtenerReportes(date = '', colegioCodigo = null) {
        let Params = new HttpParams();
        if (date !== '') {
            Params = Params.append('fecha', date);
        }
        if (colegioCodigo) {
            Params = Params.append('colegioCodigo', colegioCodigo);
        }
        const headers = new HttpHeaders()
            .set('Accept', 'application/json');
        return this.httpClient.get(this.pathAPI + '/rutas', {params: Params, headers});
    }

    informeLogueados(colegioCodigo) {
        let Params = new HttpParams();
        Params = Params.append('colegioCodigo', colegioCodigo);

        const headers = new HttpHeaders()
            .set('Accept', 'application/json');
        return this.httpClient.get(this.pathAPI + '/informes/padres-logueados', {params: Params, headers});
    }

    informeEstudiantesSalon(colegioCodigo) {
        let Params = new HttpParams();
        Params = Params.append('colegioCodigo', colegioCodigo);

        const headers = new HttpHeaders()
            .set('Accept', 'application/json');
        return this.httpClient.get(this.pathAPI + '/informes/estudiantes-grupos', {params: Params, headers});
    }
}
