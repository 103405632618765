import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Sesion } from '../models/sesion';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{
  /**
   * Interceptando las peticiones para firmarlos con un tken en la cabecera si hay una sesion iniciada
   * @param req 
   * @param next 
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;
    headers = headers.append('Accept', 'application/json');
    const sesion = JSON.parse(localStorage.getItem('g2s-sesion')) as Sesion;

    if (sesion && sesion.token.access_token) {
      return next.handle(
        req.clone({
          headers: headers.append('Authorization', 'Bearer ' + sesion.token.access_token)
        })
      );
    }

    return next.handle(req);
  }
}
