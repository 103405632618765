import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Usuario } from '../models/usuario';
import { HttpErrorResponse } from '@angular/common/http';
import { Token } from '../models/token';
import { Sesion } from '../models/sesion';
import { UsuarioService } from '../services/usuario.service';
import { SnackBarService } from '../utils/snack-bar.service';
import * as uuid from 'uuid';
import { first, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-iniciar-sesion',
  templateUrl: './iniciar-sesion.component.html',
  styleUrls: ['./iniciar-sesion.component.scss'],
})
export class IniciarSesionComponent implements OnInit {
  iniciarSesionForm: FormGroup;
  loader = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private usuarioService: UsuarioService,
    private snackBarService: SnackBarService
  ) {
    this.iniciarSesionForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      clave: ['', Validators.required],
    });
  }

  ngOnInit() {
    if (this.authService.getSesion()) {
      this.router.navigate(['/home']);
    } else {
      this.authService.sesionIniciada
        .asObservable()
        .pipe(first())
        .subscribe({
          next: (iniciada) => (iniciada ? this.router.navigate(['/home']) : null),
        });
    }
  }

  onSubmit(): void {
    this.loader = true;
    const usuario = this.iniciarSesionForm.getRawValue() as Usuario;
    const sesion = new Sesion();

    this.authService
      .iniciarSesion(usuario, uuid.v4())
      .pipe(
        tap((token: Token) => {
          sesion.token = token;
          this.authService.guardarSesion(sesion);
        }),
        switchMap((token: Token) => {
          return this.usuarioService.obtenerConfiguracionUsuario();
        })
      )
      .subscribe(
        (response) => {
          this.loader = false;

          this.authService.cambiarEstadoSesionIniciada(true, sesion);
        },
        (errorResponse: HttpErrorResponse) => {
          this.loader = false;

          this.snackBarService.mensajeSimple(
            'Usuario o contraseña incorrectos, por favor verifique.'
          );
          console.log('Error-iniciarSesion', errorResponse.message);
          this.authService.cambiarEstadoSesionIniciada(false);
        }
      );
  }
}
