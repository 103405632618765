import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Observable } from 'rxjs';
import { Usuario } from '../models/usuario';
import { Sesion } from '../models/sesion';
import { Token } from '../models/token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public sesionIniciada: Subject<boolean> = new Subject<boolean>();
  public sesion: Sesion;
  public pathApi = environment.baseUrlApi + '/oauth/token';

  constructor(private httpClient: HttpClient) {}

  verificarSesion() {
    const sesion = JSON.parse(localStorage.getItem('g2s-sesion')) as Sesion;
    this.sesion = sesion;
    this.cambiarEstadoSesionIniciada(sesion != null, sesion);
  }

  obtenerEstudiantes() {
    return JSON.parse(localStorage.getItem('estudiantes'));
  }

  guardarEstudiantes(estudiantes) {
    localStorage.setItem('estudiantes', JSON.stringify(estudiantes));
  }

  guardarConfiguracion(data) {
    localStorage.setItem('configuracion', JSON.stringify(data));
  }

  obtenerConfiguracion() {
    return JSON.parse(localStorage.getItem('configuracion'));
  }

  /**
   * Obtener sesion almacenada en localstorage
   */
  getSesion(): Sesion {
    this.sesion = JSON.parse(localStorage.getItem('g2s-sesion')) as Sesion;

    return this.sesion;
  }

  /**
   * Cambiar el estado de la sesion cuando se inicie o cuando se cierre la misma notificando al subject.
   * @param iniciada
   * @param sesion
   */
  cambiarEstadoSesionIniciada(iniciada: boolean, sesion: Sesion = null) {
    // if (iniciada) {
    //   console.log('setItem localstorage');
    //   localStorage.setItem('g2s-sesion', JSON.stringify(sesion));
    // } else {
    //   console.log('CLEAR localstorage');
    //   localStorage.clear();
    // }

    // this.sesion = sesion;

    if (!iniciada) {
      localStorage.clear();
    }

    this.sesionIniciada.next(iniciada);
  }

  guardarSesion(sesion) {
    localStorage.setItem('g2s-sesion', JSON.stringify(sesion));
  }

  eliminarSesion() {
    localStorage.clear();
  }

  /**
   * Inicio de sesion del usuario.
   * @param usuario
   */
  iniciarSesion(usuario: Usuario, uuid: string): Observable<Token> {
    return this.httpClient.post<Token>(this.pathApi, {
      device_description: 'pc-' + uuid,
      device_uid: uuid,
      token_fcm: '12345',
      grant_type: 'password',
      client_id: 1,
      client_secret: '4TT2u8Y9N9qB2jCGHpsMTVmQFUfrEBGK5MqKDaBV',
      username: usuario.email,
      password: usuario.clave,
    });
  }
}
