import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { GroupService } from '../services/group.service';

@Component({
  selector: 'app-videoconference',
  templateUrl: './videoconference.component.html',
  styleUrls: ['./videoconference.component.scss'],
})
export class VideoconferenceComponent implements OnInit {
  createVideconferenceForm = this.formBuilder.group({
    name: ['', [Validators.required]],
    welcome: ['', [Validators.required]],
  });
  loader = false;

  constructor(
    private matDialogRef: MatDialogRef<VideoconferenceComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private formBuilder: FormBuilder,
    private groupService: GroupService
  ) {}

  ngOnInit(): void {}

  createVideoconference() {
    this.loader = true;

    this.groupService
      .createVideoconference(this.dialogData.groupCode, this.name.value, this.welcome.value)
      .subscribe({
        next: () => {
          this.loader = false;
          this.closeDialog();
        },
        error: (error) => {
          this.loader = false;
          alert(error);
        },
      });
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  get name() {
    return this.createVideconferenceForm.get('name');
  }

  get welcome() {
    return this.createVideconferenceForm.get('welcome');
  }
}
