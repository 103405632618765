import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IniciarSesionComponent} from './iniciar-sesion/iniciar-sesion.component';
import {AuthGuard} from './auth/auth.guard';
import {MapComponent} from './map/map.component';
import {ShowMapComponent} from './show-map/show-map.component';

const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: 'home'},
    {path: 'mapa', component: MapComponent, canActivate: [AuthGuard]},
    {
        path: 'administracion',
        loadChildren: './administracion/administracion.module#AdministracionModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'rutas',
        loadChildren: './rutas/rutas.module#RutasModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'mensajes',
        loadChildren: './mensajes/mensajes.module#MensajesModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'actividades',
        loadChildren: './actividades/actividades.module#ActividadesModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'usuarios',
        loadChildren: './usuarios/usuarios.module#UsuariosModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'home',
        loadChildren: './home/home.module#HomeModule',
        canActivate: [AuthGuard]
    },
    {path: 'iniciar-sesion', component: IniciarSesionComponent},
    {path: 'mostrar-mapa', component: ShowMapComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
