import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ColegioService} from '../services/colegio.service';
import {LatLng, MapsAPILoader, AgmMap} from '@agm/core';
import {RutasService} from '../services/rutas.service';
import {UtilsService} from '../services/utils.service';
import {SnackBarService} from '../utils/snack-bar.service';

@Component({
    selector: 'app-show-map',
    templateUrl: './show-map.component.html',
    styleUrls: ['./show-map.component.scss']
})
export class ShowMapComponent implements AfterViewInit {
    school;
    group;
    kidList = [];
    lat;
    lng;
    bounds;
    mapType;
    eventCode;
    routeCode;

    policeStation = [];
    altoRiesgo = [];
    hospital = [];

    constructor(private activatedRoute: ActivatedRoute,
                private colegioProvider: ColegioService,
                private mapsAPILoader: MapsAPILoader,
                private rutaService: RutasService,
                private utils: UtilsService,
                private snackBarService: SnackBarService) {
    }

    private setPoliceStation(event) {
        if (event.checked) {
            this.policeStation = [
                {
                    name: 'Policia Cai Acopi Yumbo',
                    lat: '3.4954089',
                    lng: '-76.5224683',
                    phone: ' 5518027'
                },
                {
                    name: 'Policia Nacional Dirección de Bienestar Social Hogar de Paso Madre Maria de San Luis',
                    lat: '3.4618038',
                    lng: '-76.5343451',
                    phone: '5518027'
                },
                {
                    name: 'Gaula alameda',
                    lat: '3.4374507',
                    lng: '-76.5376496',
                    phone: '5518027'
                },
                {
                    name: 'Policia Nacional Estación El Guabal',
                    lat: '3.4129791',
                    lng: '-76.5273285',
                    phone: '3250440'
                },
                {
                    name: 'Estación de Policía Jamundí Kr 9a Cl 15a',
                    lat: '3.2665757',
                    lng: '-76.5372419',
                    phone: '3250440'
                },
                {
                    name: 'Inspección de Policía Jamundí',
                    lat: '3.261876',
                    lng: '-76.541307',
                    phone: ' 5518027'
                }];
        } else {
            this.policeStation = [];
        }
    }

    private setHospital(event) {
        if (event.checked) {
            this.hospital = [
                {
                    name: 'Hospital Joaquín Paz Borrero',
                    lat: 3.4651451,
                    lng: -76.4822888,
                    phone: 4184747
                },
                {
                    name: 'Clinica Nueva Rafael Uribe Uribe',
                    lat: 3.4672441,
                    lng: -76.524893,
                    phone: 4850115
                },
                {
                    name: 'Tu Salud',
                    lat: 3.4612148,
                    lng: -76.5281546,
                    phone: 5247373
                },
                {
                    name: 'Hospital de San Juan De Dios',
                    lat: 3.4548856,
                    lng: -76.5277791,
                    phone: 8937711
                },
                {
                    name: 'Hospital Basico Cañaveralejo',
                    lat: 3.4450651,
                    lng: -76.5432715,
                    phone: 8937711
                },
                {
                    name: 'Hospital Primitivo Iglesias',
                    lat: 3.4434373,
                    lng: -76.5103233,
                    phone: '318 7818483'
                },
                {
                    name: 'Fundación Clínica Infantil Club Noel',
                    lat: 3.4398282,
                    lng: -76.53795,
                    phone: 4854404
                },
                {
                    name: 'Hospital Universitario Del Valle Sede Pediatría y Oncología',
                    lat: 3.4336381,
                    lng: -76.5469837,
                    phone: 5542353
                },
                {
                    name: 'Hospital Carlos Holmes Trujillo',
                    lat: 3.4187409,
                    lng: -76.49423,
                    phone: 4377777
                },
                {
                    name: 'Hospital Isaías Duarte Cancino E.S.E.',
                    lat: 3.4113083,
                    lng: -76.4848208,
                    phone: 4140727
                },
                {
                    name: 'Departamental Hospital',
                    lat: 3.4300075,
                    lng: -76.5445697,
                    phone: 4140727
                },
                {
                    name: 'Comfandi Tequendama',
                    lat: 3.4217396,
                    lng: -76.5467262,
                    phone: '315 6598177'
                },
                {
                    name: 'Red de Salud del Suroriente E.S.E.',
                    lat: 3.4160528,
                    lng: -76.5334117,
                    phone: '315 6598177'
                },
                {
                    name: 'Hospital Cañaveralejo',
                    lat: 3.4151103,
                    lng: -76.5383148,
                    phone: 8937711
                },
                {
                    name: 'Hospital Carlos Carmona Montoya',
                    lat: 3.4097126,
                    lng: -76.5147221,
                    phone: 3280847
                },
                {
                    name: 'Hospital Psiquiátrico Universitario Del Valle',
                    lat: 3.387559,
                    lng: -76.5454549,
                    phone: 3223232
                },
                {
                    name: 'Clinica Valle De Lili',
                    lat: 3.372372,
                    lng: -76.5260035,
                    phone: 3223232
                },
                {
                    name: 'Clínica Policía Regional De Occidente',
                    lat: 3.3784233,
                    lng: -76.5167338,
                    phone: 5240930
                },
                {
                    name: 'Centro Medico Del Parque S.A.S',
                    lat: 3.2675558,
                    lng: -76.5364319,
                    phone: '313 5989724'
                },
                {
                    name: 'Clínica Integral de la Mujer',
                    lat: 3.262342,
                    lng: -76.540643,
                    phone: 5160443
                },
                {
                    name: 'Hospital Piloto de Jamundi',
                    lat: 3.2574308,
                    lng: -76.544441,
                    phone: 4868676
                },
                {
                    name: 'Centro Medico Jamundi S.A.',
                    lat: 3.2564427,
                    lng: -76.5363649,
                    phone: 5166907
                }
            ];
        } else {
            this.hospital = [];
        }
    }

    private loadParams() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.school = params.school || '';
            this.group = params.group || '';
            this.mapType = params.type || '1';
            this.eventCode = params.report || '';
            this.routeCode = params.ruta || '';

            if (this.eventCode === '') {
                this.loadData();
            } else {
                this.loadPickupPoints();
            }
        });
    }

    private setAltoRiesgo(event) {
        if (event.checked) {
            this.altoRiesgo = [
                {
                    name: 'Zona 1',
                    lat: 3.48970522606713,
                    lng: -76.5338166536437,
                    radius: 296
                },
                {
                    name: 'Zona 2',
                    lat: 3.48357968369557,
                    lng: -76.4822116524299,
                    radius: 821
                },
                {
                    name: 'Zona 3',
                    lat: 3.45042382417652,
                    lng: -76.5238396779563,
                    radius: 821
                },
                {
                    name: 'Zona 4',
                    lat: 3.43166068409774,
                    lng: -76.4785210368587,
                    radius: 1727
                },
                {
                    name: 'Zona 5',
                    lat: 3.40638539138099,
                    lng: -76.4980047898537,
                    radius: 1727
                },
                {
                    name: 'Zona 6',
                    lat: 3.42283572606312,
                    lng: -76.560845424705,
                    radius: 945
                },
                {
                    name: 'Zona 7',
                    lat: 3.37716821325299,
                    lng: -76.5330363011543,
                    radius: 516
                }
            ];
        } else {
            this.altoRiesgo = [];
        }
    }

    ngAfterViewInit(): void {
        this.loadParams();
    }

    loadData() {
        if (this.group !== '') {
            this.colegioProvider.obtenerEstudiantesPorGrupoDeRuta(this.group).subscribe(response => {
                this.kidList = [];
                response.forEach(value => {
                    this.kidList.push({
                        code: value.codigo.toString(),
                        lat: value.persona.latitud_principal,
                        lng: value.persona.longitud_principal
                    });
                });
                this.setLatLng();
            });
        } else if (this.school !== '') {
            this.loadDataSchool();
        }
    }

    loadPickupPoints() {
        if (this.routeCode !== '') {
            this.loadPickUpSchool();
        }
    }

    loadPickUpSchool() {

        this.rutaService.obtenerPuntos(this.routeCode, this.eventCode).subscribe(response => {
            this.kidList = [];
            if (response.length > 0) {
                let i = 1;
                response.forEach(value => {
                    this.kidList.push({
                        code: i.toString(),
                        student_code: value.codigo.toString(),
                        lat: parseFloat(value.latitud),
                        lng: parseFloat(value.longitud),
                        name: value.nombre_completo,
                        dir: value.direccion,
                        date: value.fecha,
                        observation: value.observacion
                    });
                    i++;
                });
                this.setLatLng();
            } else {
                this.snackBarService.mensajeSimple('No existen registros para mostrar');
            }
        });
    }

    private loadDataSchool() {
        this.colegioProvider.obtenerEstudiantesColegio(this.school).subscribe(response => {
            this.kidList = [];
            response.forEach(value => {
                this.kidList.push({
                    code: value.codigo.toString(),
                    lat: value.persona.latitud_principal,
                    lng: value.persona.longitud_principal,
                    name: value.persona.nombre_completo,
                    dir: value.persona.direccion_principal,
                });
            });
            this.setLatLng();
        });
    }

    private setLatLng() {
        /*const bounds = this.getCenterFromDegrees(this.kidList);
        console.log(bounds);
        this.lat = bounds.lat();
        this.lng = bounds.lng();*/
        this.mapsAPILoader.load().then(() => {
            this.bounds = new window['google'].maps.LatLngBounds();
            this.kidList.forEach(value => {
                this.bounds.extend(new window['google'].maps.LatLng(value.lat, value.lng));
            });
        });
    }
}
