import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { UsuarioService } from '../services/usuario.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  sesionIniciada = false;

  mostrarEmpresa = false;
  mostrarColegio = false;
  mostrarMapa = false;
  mostrarMensaje = false;
  mostrarActividades = false;

  logo;
  config;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    share()
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router,
    private userService: UsuarioService
  ) {
    const fullURL = window.location.host.split('.');
    const subDomain = fullURL[0];
    if (subDomain === 'jardinet-edunet') {
      this.logo = 'logo-edunet';
    } else if (subDomain === 'cmsa') {
      this.logo = 'logo-sf';
    } else {
      this.logo = 'logo-g2s';
    }
  }

  ngOnInit() {
    this.authService.sesionIniciada.subscribe((sesionIniciada: boolean) => {
      this.sesionIniciada = sesionIniciada;

      if (!this.sesionIniciada) {
        this.router.navigate(['/iniciar-sesion']);
      } else {
        this.userService.obtenerConfiguracionUsuario().subscribe((response) => {
          this.config = response;
          this.mostrarActividades = true;

          if (response.perfil_codigo === 4) {
            this.mostrarEmpresa = false;
            this.mostrarColegio = false;
            this.mostrarMapa = false;
            this.mostrarMensaje = true;

            this.authService.guardarEstudiantes(response.estudiantes);
          } else if (response.perfil_codigo === 7) {
            this.mostrarEmpresa = false;
            this.mostrarColegio = true;
            this.mostrarMapa = false;
            this.mostrarMensaje = true;
          } else if (response.perfil_codigo === 1) {
            this.mostrarEmpresa = true;
            this.mostrarColegio = true;
            this.mostrarMapa = true;
            this.mostrarMensaje = true;
          }
        });
      }
    });

    this.authService.verificarSesion();
  }

  cerrarSesion() {
    this.authService.cambiarEstadoSesionIniciada(false);
  }

  sesionUsuario() {
    this.router.navigate(['/usuarios/cambio-clave']);
  }
}
