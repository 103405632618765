import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor (
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.verificarSesion();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  /**
   * Metodo para verificar si la sesion esta iniciada y en caso de que no, redirige a la pagina de inicio de sesion.
   */
  verificarSesion(): boolean {
    const sesion = localStorage.getItem('g2s-sesion');

    if (sesion) { return true; }

    this.router.navigate(['iniciar-sesion']);
    return false;
  }
}
