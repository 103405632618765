import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { VideoconferenceComponent } from './videoconference.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [VideoconferenceComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
})
export class VideoconferenceModule {}
