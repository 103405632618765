import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  mensajeSimple(mensaje: string, duracion: number = 3000) {
    this.snackBar.open(mensaje, 'Aceptar', {
      duration: duracion
    });
  }
}
