import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ColegioService} from '../services/colegio.service';
import {GroupService} from '../services/group.service';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {

    colegioList = [];
    colegioSelected;
    groupList = [];
    groupSelected;
    mapType = '1';

    constructor(private colegioService: ColegioService,
                private groupService: GroupService) {
    }

    ngAfterViewInit() {
        this.obtenerColegios();
    }

    obtenerColegios() {
        this.colegioService.obtenerColegios().subscribe(response => {
            this.colegioList = response;
        }, error => {
        });
    }

    onSelectionChange() {
        this.groupList = [];
        this.obtenerGrupos();
    }

    obtenerGrupos() {
        const param = {
            school: this.colegioSelected,
            company: 3
        };
        this.groupService.getGroupByCompany(param).subscribe(response => {
            this.groupList = response;
        });
    }

    ngOnInit(): void {
    }

    showPrintView() {
        if (this.colegioSelected !== undefined) {
            let url = '/mostrar-mapa?hasFullView=true&school=' + this.colegioSelected + '&type=' + this.mapType;
            if (this.groupSelected !== undefined) {
                url += '&group=' + this.groupSelected;
            }
            window.open(url);
        } else {
            alert('Debe seleccionar al menos un colegio para continuar');
        }
    }
}
